import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BaseModal} from '../../../../classes/base-modal';
import {AbstractControl, AsyncValidatorFn, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {User} from '../../classes/user';
import {DataService} from '../../../../services/data.service';
import {GenEmailChangeRequest} from '../../../../../../generated/serverModels/GenEmailChangeRequest';
import {UserService} from '../../services/user.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {forkJoin, of} from 'rxjs';
import {ApplicationConfig} from '../../../../classes/application-config';
import {map} from 'rxjs/operators';
import {AuthenticationService} from '../../../../services/authentication.service';

@Component({
    selector: 'eaglei-user-info-modal',
    templateUrl: './user-info-modal.component.html',
    styleUrls: ['./user-info-modal.component.scss'],
    standalone: false,
})
export class UserInfoModalComponent extends BaseModal implements OnInit {
    public infoGroup: UntypedFormGroup;
    public contactGroup: UntypedFormGroup;
    public requestGroup: UntypedFormGroup;

    public readonly user: User;
    public readonly states: string[] = DataService.states.getValue().map((s) => s.abbreviation);

    public emailChangeRequest = new GenEmailChangeRequest();
    public emailChangeRequestActive: boolean;

    constructor(
        private ref: MatDialogRef<UserInfoModalComponent>,
        @Inject(MAT_DIALOG_DATA) private data: User,
        private userService: UserService,
        private popup: MatSnackBar,
        private auth: AuthenticationService
    ) {
        super(85);
        this.user = data.getPersonalInfo();
    }

    ngOnInit(): void {
        const textPattern = Validators.pattern(/[.*[\S].*/);

        const infoControls = {
            firstName: new UntypedFormControl('', {updateOn: 'change', validators: [Validators.required, textPattern]}),
            lastName: new UntypedFormControl('', {updateOn: 'change', validators: [Validators.required, textPattern]}),
            jobTitle: new UntypedFormControl('', {updateOn: 'change', validators: [Validators.required, textPattern]}),
            department: new UntypedFormControl('', {updateOn: 'change', validators: [Validators.required, textPattern]}),
            organization: new UntypedFormControl('', {updateOn: 'change', validators: [Validators.required, textPattern]}),
            city: new UntypedFormControl('', {updateOn: 'change', validators: [Validators.required, textPattern]}),
            state: new UntypedFormControl('', {updateOn: 'change', validators: [Validators.required, textPattern]}),
            postalCode: new UntypedFormControl('', {
                updateOn: 'change',
                validators: [Validators.required, textPattern, this.postalCodeValidator()],
            }),
        };

        const contactControls = {
            email: new UntypedFormControl('', {updateOn: 'change', validators: [Validators.required, textPattern, Validators.email]}),
            phoneNumber: new UntypedFormControl('', {
                updateOn: 'change',
                validators: [Validators.required, textPattern, this.phoneNumberValidator()],
            }),
            reason: new UntypedFormControl('', {updateOn: 'change', validators: [Validators.required, textPattern]}),
        };

        const requestControls = {
            email: new UntypedFormControl('', {
                updateOn: 'blur',
                validators: [Validators.required, Validators.email],
                asyncValidators: [this.uniqueEmailValidator()],
            }),
            reason: new UntypedFormControl('', {updateOn: 'blur', validators: [Validators.required]}),
        };

        Object.keys(infoControls).forEach((key) => {
            (infoControls[key] as UntypedFormControl).markAsTouched();
        });

        Object.keys(contactControls).forEach((key) => {
            (contactControls[key] as UntypedFormControl).markAsTouched();
        });

        contactControls.email.disable();

        this.infoGroup = new UntypedFormGroup(infoControls);
        this.contactGroup = new UntypedFormGroup(contactControls);
        this.requestGroup = new UntypedFormGroup(requestControls);
    }

    /**
     * NgAfterInit called from parent class
     */
    afterInit() {}

    /**
     * Checks the value of each form group to determine if the current data can be saved.
     */
    public isFormValid(): boolean {
        const requestCheck = this.emailChangeRequestActive ? this.requestGroup.valid : true;
        return this.infoGroup.valid && this.contactGroup.valid && requestCheck;
    }

    /**
     * Shows or hides the email request boxes.
     */
    public toggleEmailChangeRequest(): void {
        this.emailChangeRequestActive = !this.emailChangeRequestActive;

        if (!this.emailChangeRequestActive) {
            this.emailChangeRequest = new GenEmailChangeRequest();
            this.requestGroup.reset();
        }
    }

    /**
     * Attempts to save the user info and throws an error if problems are encountered
     */
    public save(): void {
        const success = () => {
            this.popup.open('Updated user information', 'Okay', {duration: 5000, panelClass: 'dialog-success'});
            this.ref.close(this.user);
        };

        const failure = (error: any) => {
            const text = error.error.userMessage || 'Failed to update user information';
            this.popup.open(text, 'Okay', {duration: 5000, panelClass: 'dialog-failure'});
        };

        const calls = [this.userService.partiallyUpdateUser(this.user)];
        if (this.emailChangeRequestActive) {
            this.emailChangeRequest.userId = this.user.id;
            calls.push(this.userService.requestEmailChange(this.emailChangeRequest));
        }

        forkJoin(calls).subscribe(success, failure);
    }

    public checkNumberInput(event: KeyboardEvent) {
        ApplicationConfig.numberInputValidation(event, true, false);
    }

    /**
     * Converts the formatted phone number back into a simple number.
     * @param event the value if the phone input
     * @param isPoc If the phone number to be updated is the poc phone.
     */
    public transformPhone(event: any, isPoc: boolean = false): void {
        if (event) {
            const transformedNumber = event.toString().replace(/[()-\s]/g, '');
            if (isPoc) {
                this.user.poc.telephone = transformedNumber;
            } else {
                this.user.telephone = transformedNumber;
            }
        }
    }

    /**
     * Checks to see if the postal code is in the format of XXXXX or XXXXX-XXXX
     */
    public postalCodeValidator(): ValidatorFn {
        return (control) => {
            if (control.value) {
                const regex = /^\d{5}(?:-\d{4})?$/;
                const test = regex.test(control.value);
                return test ? null : {format: 'Postal code must be XXXXX or XXXXX-XXXX'};
            }
            return null;
        };
    }

    private uniqueEmailValidator(): AsyncValidatorFn {
        return (control: AbstractControl) => {
            if (control.value && (control.value as string).indexOf('@') !== 0) {
                return this.auth
                    .checkEmailExists(control.value)
                    .pipe(map((exists) => (exists ? {unique: 'An account exists with this email'} : null)));
            }
            return of(null);
        };
    }

    /**
     * checks to see if a phone number is the correct length
     */
    public phoneNumberValidator(): ValidatorFn {
        return (control) => {
            if (control.value) {
                const regex = /^[0-9()-\s]{14}$/;
                return regex.test(control.value) ? null : {length: 'Phone number must be a valid 10 digit number.'};
            }
            return null;
        };
    }
}
